.editor {
  position: relative;
  height: 100vh;
}
.editor .pnlm-container {
  height: 100% !important;
}

.editor .iconContainer {
  display: flex;
  gap: 1rem;
  justify-content: end;
  margin: 0.5rem 0;
}

.floating-menu {
  position: absolute;
  top: 5px;
  padding: 10px;
  right: 2px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
  background-color: var(--primary-transparent-background-color);

  color: white;
}

.floating-menu label,
.floating-menu input,
.floating-menu select,
.floating-menu .action-button {
  display: block;
  margin: 4px 0;
}

.floating-menu .action-button {
  padding: 5px 10px;
  background-color: rgba(106, 51, 255, 0.8);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.floating-menu input,
.floating-menu select {
  background-color: white;
  color: rgb(0, 0, 0);
  border: none;
}

.language-button {
  height: 100%;
  width: 110px;
}

.lang-container {
  position: relative;
}

.language-dropdown {
  position: relative;
  /* background-color: rgba(106, 51, 255, 0.8); */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  z-index: 20;
  width: 90px;
  top: 2.5rem;
}

.language-dropdown .action-button {
  font-size: 1rem;
  color: rgb(255, 255, 255);
  cursor: pointer;
  width: 100%;
  text-align: left;
  padding: 0px 10px;
  margin: 0;
  border: solid rgba(255, 255, 255, 0.5) 1.5px;
}

.outer-container {
  margin: 0;
}

.volume-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: end;
  gap: 5px;
}

.floating-menu .nav-stop-button {
  font-size: 1rem;
  padding: 3px;
  background-color: transparent;
  color: rgb(255, 255, 255);
}

.floating-menu .music-slider {
  width: 120px;
  accent-color: rgb(106, 51, 255);
}

.button-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: end;
  gap: 5px;
}

@media screen and (max-width: 570px) {
  .floating-menu {
    position: absolute;
    top: 5px;
    padding: 7px;
    right: 2px;
  }

  .floating-menu .action-button {
    /* padding: 5px 10px; */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .SearchByID {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .ModelOption {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .music {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .image {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .input-box {
    width: 130px;
  }
  .language-dropdown {
    top: 2.2rem;
  }

  .floating-menu label,
  .floating-menu input,
  .floating-menu select,
  .floating-menu .action-button {
    margin: 4px 0;
  }
  .nav-stop-button {
    padding-top: 10px;
    padding-bottom: 0;
  }
}
