.container {
  max-width: 500px;
  height: 150px;
  padding: 6px;
  position: absolute;
  bottom: 1%;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--primary-transparent-background-color);
  backdrop-filter: blur(4px);
  box-shadow: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttonAndIconContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ToggleButtonsContainer {
  display: flex;
  justify-content: center;
  gap: 20px;
}

#Home .iconContainer {
  margin-left: 120px;
  display: flex;
}

#Home .iconContainer i {
  margin-left: 10px;
}

.section {
  width: 100%;
  align-items: center;
  /* margin-bottom: 10%;
  height: 25%; */
}

[data-input-mode="image"] [data-input-section="music"] {
  display: none;
}

[data-input-mode="music"] .ToggleButtonMusic {
  border-bottom: 2px solid red;
}

[data-input-mode="music"] [data-input-section="image"] {
  display: none;
}

[data-input-mode="image"] .ToggleButtonImage {
  border-bottom: 2px solid red;
}

.input-text {
  width: calc(95% - 20px);
  height: 40px;
  background-color: transparent;
  padding: 10px;
  box-sizing: border-box;
  outline: none;
  color: #fff;
  transition: border-color 0.3s ease, background-color 0.3s ease;
  border: none;
}

.input-password {
  width: 30%;
  height: 80%;
  background-color: transparent;
  padding: 10px;
  box-sizing: border-box;
  outline: none;
  color: #fff;
  transition: border-color 0.3s ease, background-color 0.3s ease;
  border: none;
}

.input-text::placeholder,
.input-password::placeholder {
  color: #eee;
  font-weight: bold;
}

.input-text:hover,
.input-password:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.input-text:focus,
.input-password:focus {
  color: #fff;
  border-bottom: 2px solid red;
}

.prompt-button {
  padding: 4px 1%;
  color: #fff;
  background-color: rgba(106, 51, 255, 0.5);
  backdrop-filter: blur(4px);
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: bold;
  width: 250px;
}

.prompt-button:hover {
  background-color: #000;
  color: #fff;
}

.prompt-button:active {
  transform: translateY(1px);
}

.clear-button {
  margin-left: 10px;
}

.icon {
  transform: translateY(25%);
  pointer-events: none;
  cursor: not-allowed;
  fill: none;
  stroke: white;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.clear-button:hover .icon {
  opacity: 0.3;
}

.model-styles {
  width: 30%;
  padding: 10px 5px;
  border-radius: 3px;
  border: 1px solid #999;
  background-color: transparent;
  color: #999;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  margin-right: -30%;
}

.model-styles:hover {
  background-color: #ccc;
}

.model-styles:focus {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.notification {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background: #333;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
  animation: fadeOut 3s forwards;
}

@keyframes fadeOut {
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media screen and (max-width: 450px) {
  .container {
    width: 95%;
    height: 150px;
    justify-content: center;
    align-items: center;
  }
  .input-text {
    height: 30px;
  }
  .section {
    width: 100%;
    align-items: center;
    margin-bottom: 10%;
    height: 25%;
  }
  .ToggleButtonsContainer {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  .prompt-button {
    width: 70%;
    padding: 1%;
  }
  #Home .iconContainer {
    margin-left: 20%;
  }
}

.loadingPopup {
  position: absolute;
  inset: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  top: -160%;
}

.loadingPopupContent {
  width: fit-content;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 1rem;
  border-radius: 0.5rem;
  font-size: 1rem;
}

.downloadingPopup {
  position: absolute;
  inset: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  top: -160%;
}

.downloadingPopupContent {
  width: fit-content;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 1rem;
  border-radius: 0.5rem;
  font-size: 1rem;
}
